<template>
  <div class="Shipment" id="Shipment">
    <div class="list-header">
      <div
        class="openForm"
        @click="clickCloseSearch()"
        v-show="SearchType == 1 && !closeSearch"
      >
        <svg-icon icon-class="filter"></svg-icon>{{$t('lang.BText1')}}
      </div>

      <div
        class="header-left"
        :class="{
          open: closeSearch,
          close: openSearch,
          smallHeader: SearchType == 1,
        }"
        v-show="(SearchType == 1 && closeSearch) || SearchType == 2"
      >
        <!-- <div
          class="openForm"
          @click="clickCloseSearch()"
          v-show="SearchType == 1 && closeSearch"
        >
          <svg-icon icon-class="filter"></svg-icon>Filter
        </div> -->
        <div class="form">
          <div class="search-item">
            <div class="form-item-title">{{$t('lang.NText141')}}</div>
            <el-input
              class="shipmentNo"
              v-model="searchForm.shipmentNo"
              type="textarea"
              :autosize="{ minRows: 1 }"
              resize="none"
            >
            </el-input>
          </div>
          <div class="search-item">
            <div class="form-item-title">{{$t('lang.BText2')}}</div>
            <el-select
              class="date-card-select"
              v-model="searchForm.seachStatus"
              :placeholder="$t('lang.BText80')"
              clearable
            >
              <el-option
                v-for="item in StatusList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="search-item">
            <div class="form-item-title">{{$t('lang.NText142')}}</div>
            <el-select
              class="date-card-select"
              v-model="searchForm.seachType"
              :placeholder="$t('lang.BText80')"
              clearable
            >
              <el-option
                v-for="item in ShipmentTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>

          <div class="search-item">
            <div class="form-item-title">{{$t('lang.BText107')}}</div>
            <el-select
              class="date-card-select"
              v-model="searchForm.seachPort"
              placeholder=""
              filterable
              remote
              :remote-method="findPortList"
              :loading="selectLoading"
            >
              <el-option
                v-for="item in portList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>

          <div class="search-item">
            <div class="form-item-title">{{$t('lang.BText17')}}</div>
            <el-input class="insurance-input" v-model="searchForm.seachShipper">
            </el-input>
          </div>
          <div class="search-item">
            <div class="form-item-title">{{$t('lang.BText3')}}</div>
            <el-input
              class="insurance-input"
              v-model="searchForm.seachConsignee"
            >
            </el-input>
          </div>
          <div class="search-item">
            <div class="form-item-title">{{$t('lang.NText143')}}</div>
            <el-input class="insurance-input" v-model="searchForm.seachNotify">
            </el-input>
          </div>

          <div class="search-item">
            <div class="form-item-title">{{$t('lang.QText21')}}</div>
            <el-date-picker
              v-model="seachCargoReadyDay"
              type="daterange"
              :range-separator="$t('lang.to')"
              :start-placeholder="$t('lang.NText300')"
              :end-placeholder="$t('lang.NText209')"
              @change="seachCargoReadyDayChange"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </div>
          <div class="search-item">
            <div class="form-item-title">{{$t('lang.BText5')}}</div>
            <el-date-picker
              v-model="seachCreateDate"
              type="daterange"
              :range-separator="$t('lang.to')"
              :start-placeholder="$t('lang.NText300')"
              :end-placeholder="$t('lang.NText209')"
              @change="seachCreateDateChange"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="search-button">
          <div class="refresh-button" @click="clearForm">
            <svg-icon icon-class="refresh"></svg-icon>
          </div>
          <el-button class="searchbutton" @click="query">{{$t('lang.BText108')}}</el-button>
          <svg-icon
            v-show="!closeSearch"
            class="icon"
            icon-class="downTriangle"
            @click="clickCloseSearch()"
          ></svg-icon>
          <svg-icon
            v-show="closeSearch"
            class="icon"
            icon-class="upTriangle"
            @click="clickOpenSearch()"
          ></svg-icon>
        </div>
      </div>

      <div class="header-right" v-show="!(SearchType == 1 && closeSearch)">
        <el-button class="create" @click="createBooking()"
          >{{$t('lang.BText75')}}</el-button
        >
      </div>
    </div>

    <dwlist
      :url="source.shipment_api.listData"
      :query-params="searchForm"
      ref="shipmentList"
    >
      <el-table-column prop="originPort" :label="$t('lang.BText4')" width="200px">
        <template slot-scope="scope">
          <div class="line">
            {{ scope.row.originPort }} <span style="color: #299be4"> -> </span>
            {{ scope.row.dischargePort }},{{ scope.row.dischargeCountry }}
          </div>
          <div class="line">{{ scope.row.shipmentNo }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="transportmodeCode" :label="$t('lang.BText8')" width="100px">
        <template slot-scope="scope">
          <div class="FCL">
            <el-tooltip
              class="item"
              effect="dark"
              content="Ocean Freight"
              placement="bottom"
            >
              <svg-icon
                v-if="scope.row.transportmodeCode === 'SEA'"
                class="typeIcon"
                icon-class="oceanFreight"
              ></svg-icon>
              <svg-icon class="typeIcon" icon-class="oceanFreight"></svg-icon>
            </el-tooltip>
            <div class="line"></div>
            <el-tooltip
              class="item"
              effect="dark"
              content="FCL"
              placement="bottom"
            >
              <svg-icon
                class="typeIcon"
                :icon-class="scope.row.containerMode"
              ></svg-icon>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="status" :label="$t('lang.BText9')" width="250px">
      </el-table-column>
      <el-table-column prop="GargoInfo" :label="$t('lang.BText37')" width="150px">
        <template slot-scope="scope">
          <div>{{ scope.row.cargoInfoContainerArr }}</div>
          <div class="cargoInfoWeightVolume">
            {{ scope.row.cargoInfoWeightVolume }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="eta" :label="$t('lang.NText144')" width="120px">
        <template slot-scope="scope">
          <div class="ETA" :class="{ ETAType2: scope.row.etaType == 'ETA' }">
            {{ scope.row.eta.slice(0, 10) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="etd" :label="$t('lang.NText145')" width="120px">
        <template slot-scope="scope">
          <div class="ETA" :class="{ ETAType2: scope.row.etdType == 'ETD' }">
            {{ scope.row.etd.slice(0, 10) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="cargoReadyDate"
        :label="$t('lang.QText21')"
        width="180px"
      >
        <template slot-scope="scope">
          <div>
            {{ scope.row.cargoReadyDate.slice(0, 10) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="Shipper" :label="Shipper" width="300px">
        <template slot-scope="scope">
          <div class="shipperCompanyName">
            {{ scope.row.shipperCompanyName }}
          </div>
          <div class="shipperCity">
            {{ scope.row.shipperAddress1 }}{{ scope.row.shipperAddress2
            }}<span v-show="scope.row.shipperCity">,</span
            >{{ scope.row.shipperCity
            }}<span v-show="scope.row.shipperCountryName">,</span
            >{{ scope.row.shipperCountryName }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="Consignee" :label="$t('lang.BText3')" width="300px">
        <template slot-scope="scope">
          <div class="shipperCompanyName">
            {{ scope.row.consigneeCompanyName }}
          </div>
          <div class="shipperCity">
            {{ scope.row.consigneeAddress1 }}{{ scope.row.consigneeAddress2 }}
            <span v-show="scope.row.consigneeCity">,</span>
            {{ scope.row.consigneeCity }}
            <span v-show="scope.row.consigneeCountryName">,</span>
            {{ scope.row.consigneeCountryName }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="createDate" :label="$t('lang.BText5')" width="180px">
      </el-table-column>

      <el-table-column fixed="right" width="250px">
        <template slot-scope="scope">
          <div class="buttonList">
            <el-button @click="clickView(scope.row)" class="samll-list-info"
              >{{$t('lang.BText18')}}</el-button
            >
            <!-- <el-button class="chat-icon">
              <svg-icon
                icon-class="chat"
                @click="chatIconClick(scope.row)"
              ></svg-icon>
            </el-button> -->
          </div>
        </template>
      </el-table-column>
    </dwlist>

    <el-dialog
      :visible.sync="createFlag"
      class="viewDialog dw-dialog"
      width="70%"
      @close="closeDialog"
      id="bookingDialog"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="dialog-header">
        <div class="dw-logo">
<!--			<svg-icon icon-class="dwLogo"></svg-icon>-->
		</div>
        <div class="dialog-title">
          <div class="row1 blod60">{{ dialogTitle }}</div>
        </div>
        <div class="close" @click="closeDialog()">
          <svg-icon class="close-icon" icon-class="close"></svg-icon>
        </div>
      </div>
      <div class="el-dialog-main" ref="dwDialogMain">
        <detail-dialog
          v-if="review"
          :shipmentData="shipmentData"
          :destroy-on-close="true"
          :iShipment="true"
          :toSendMsgObj="toSendMsgObj"
          type="3"
        ></detail-dialog>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import dwlist from "../../components/dwList/dwlist.vue";
import test from "../../test/test";
import dwCheckBox from "../../components/dwCheckBox/dwCheckBox.vue";
import detailDialog from "../detailDialog/detailDialog.vue";
import source from "../../utils/interFConfig";
import Template from "../Booking/template.vue";
// import detailDialog from "../detailDialog/detailDialog.vue";
export default {
  components: {
    dwlist,
    dwCheckBox,
    detailDialog,
  },
  data() {
    return {
      listData: test.listData,
      searchForm: {},
      toSendMsgObj: {},
      selectList: [],
      openSearch: false,
      closeSearch: false,
      createFlag: false,
      type: "0",
      dialogTitle: "",
      review: false,
      edit: false,
      shipmentData: null,
      showFillIn: false,
      showDelect: false,
      ShipmentTypeList: [
        {
          label: "Ocean freight - FCL",
          value: "10",
        },
        {
          label: "Ocean freight - LCL",
          value: "20",
        },
      ],
      StatusList: [],
      portList: [],
      selectLoading: false,
      seachCargoReadyDay: [],
      seachCreateDate: [],
    };
  },
  computed: {
    SearchType() {
      if (window.innerWidth < 1660) {
        return 1;
      } else {
        return 2;
      }
    },
  },
  created() {
    this.findPortList();
    this.findStatusList();
  },
  methods: {
    query() {
      this.$refs.shipmentList.query();
    },
    clickOpenSearch() {
      this.openSearch = true;
      this.closeSearch = false;
    },
    clickCloseSearch() {
      this.openSearch = false;
      this.closeSearch = true;
    },
    seachCargoReadyDayChange(e) {
      console.log(e);
      if (e) {
        this.searchForm.seachCargoReadyDayStart = e[0];
        this.searchForm.seachCargoReadyDayEnd = e[1];
      } else {
        this.searchForm.seachCargoReadyDayStart = "";
        this.searchForm.seachCargoReadyDayEnd = "";
      }
    },
    seachCreateDateChange(e) {
      if (e) {
        this.searchForm.seachCreateDateStart = e[0];
        this.searchForm.seachCreateDateEnd = e[1];
      } else {
        this.searchForm.seachCreateDateStart = "";
        this.searchForm.seachCreateDateEnd = "";
      }
    },

    clickType(type) {
      console.log(type);
      this.type = type;
    },
    clickView(data) {
      this.type = null;
      this.toSendMsgObj = { btype: "30", bid: data.shipmentNo };
      this.shipmentData = data;
      this.dialogTitle = this.$t('lang.NText146');
      this.createFlag = true;
      this.review = true;
    },
    clickReview() {
      setTimeout(() => {
        this.toTop();
      }, 10);
      this.type = null;
      this.review = true;
    },
    toTop() {
      var element = document.getElementById("bookingDialog");
      element.scrollIntoView();
    },
    closeDialog() {
      this.showDelect = false;
      this.createFlag = false;
      this.shipmentData = null;
      this.review = false;
      this.edit = false;
    },
    clickDraft() {
      this.$router.push({ path: "/Booking/Draft" });
    },
    clickTemplate() {
      this.$router.push({ path: "/Booking/Template" });
    },
    cancelEdit() {
      if (this.shipmentNo) {
        this.closeDialog();
      } else {
        this.edit = false;
        this.review = true;
      }
    },
    clickPrevious() {
      this.review = false;
      this.dialogTitle = this.$t('lang.BText34');
      this.createFlag = true;
      this.review = false;
      this.type = 1;
    },
    openCheckBox() {
      this.showDelect = true;
    },
    Cancel() {
      this.showDelect = false;
    },
    createBooking() {
      this.$router.push({ name: "Booking", params: { createFlag: true } });
    },
    findPortList(e) {
      this.selectLoading = true;
      this.$ajax
        .cPost(this.source.shipment_api.portList, {
          type: "mq_port",
          keyName: e,
        })
        .then((res) => {
          this.selectLoading = false;
          this.portList = res.data.list[this.$i18n.locale];
        });
    },
    findStatusList() {
      this.selectLoading = true;
      this.$ajax
        .cPost(this.source.shipment_api.portList, {
          type: "mq_status",
        })
        .then((res) => {
          this.selectLoading = false;
          this.StatusList = res.data.list[this.$i18n.locale];
        });
    },
    chatIconClick(row) {
      this.$emit("drawerClick", { num: row.shipmentNo });
    },
    clearForm() {
      this.searchForm = {};
      this.seachCargoReadyDay = [];
      this.seachCreateDate = [];
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/base.scss";
.Shipment {
  padding-bottom: 30px;

  .FCL,
  .LCL {
    display: flex;
    align-items: center;
    .typeIcon {
      width: 25px;
      height: 25px;
      margin-right: 10px;
    }
    .line {
      height: 15px;
      background: $mainColor;
      width: 1px;
      margin-right: 10px;
    }
  }

  .shipmentNo {
    height: 48px;
    line-height: 48px;
  }

  .list-header {
    display: flex;
    justify-content: space-between;
    z-index: 9;
    min-height: 100px;
    margin-bottom: 10px;
    .openForm {
      height: 60px;
      line-height: 60px;
      background-color: $mainColor;
      color: #fff;
      width: 160px;
      margin-top: 50px;
      .svg-icon {
        margin-right: 20px;
        margin-top: -5px;
      }
    }
    .header-left {
      display: flex;
      width: 1390px;
      justify-content: space-between;
      border-bottom: 1px solid $mainColor;

      padding-bottom: 10px;
      height: 100px;
      overflow: hidden;
      .form {
        display: flex;
        flex-wrap: wrap;

        .search-item {
          margin-right: 20px;
          margin-top: 15px;
          .form-item-title {
            text-align: left;
            color: $fontColor2;
            line-height: 40px;
          }
          .el-input__inner {
            height: 48px;
            line-height: 48px;
          }
        }
      }

      .search-button {
        display: flex;
        margin-top: 55px;
        .refresh-button {
          height: 44px;
          width: 44px;
          border: 2px solid $mainColor;
          margin-right: 10px;
          .svg-icon {
            height: 20px;
            width: 20px;
            color: $mainColor;
            margin-top: 12px;
          }
        }
        .refresh-button:hover {
          border: 2px solid $mainColor;
          background-color: $background2;
        }
        .searchbutton {
          height: 48px;
          background-color: $mainColor;
          color: #fff;
          border-radius: 0;
          font-family: "montserratregular";
          font-size: 18px;
          border: 2px solid $mainColor;
        }
        .searchbutton:hover {
          border: 2px solid $mainColor;
          background-color: $background2;
        }
        .icon {
          height: 20px;
          width: 20px;
          color: $mainColor;
          padding: 12px;
          margin: 0 10px;
        }

        .icon:hover {
          height: 26px;
          width: 26px;
          padding: 9px;
        }
      }
    }
    .open {
      transform: all 0.2s;
      animation-name: openSearch;
      animation-duration: 0.2s;
      height: 410px;
    }

    .close {
      transform: all 0.5s;
      animation-name: closeSearch;
      animation-duration: 0.5s;
      height: 100px;
      overflow: hidden;
    }

    @keyframes openSearch {
      0% {
        height: 100px;
      }

      100% {
        height: 410px;
      }
    }

    @keyframes closeSearch {
      0% {
        height: 410px;
      }
      100% {
        height: 100px;
      }
    }
    .header-right {
      width: 475px;
      text-align: right;
      margin-top: 50px;
      flex-shrink: 0;
      .el-button {
        height: 60px;
        font-size: 18px;
        color: $mainColor;
        border: 2px solid $mainColor;
        border-radius: 0;
        font-family: "montserratregular";
      }
      .el-button:hover {
        background-color: $background2;
        color: #fff;
        border: 2px solid $mainColor;
      }
      .create {
        background-color: $background2;
        color: #fff;
        border: 2px solid $background2;
        width: 210px;
        height: 60px;
        padding: 0;
        text-align: center;
      }
    }
  }

  .smallHeader {
    .header-left {
      width: 100%;
    }
  }

  .ETA {
    color: $background2;
  }
  .ETAType2 {
    color: $fontColor2;
  }

  .buttonList {
    display: flex;
    justify-content: center;
  }

  .viewDialog {
    .el-dialog__header {
      padding: 0;
    }
    .el-dialog__body {
      padding: 0;
    }
    .el-dialog__close {
      display: none;
    }
    .dialog-header {
      height: 105px;
      padding: 20px;
      background-color: $mainColor;
      display: flex;
      .dw-logo {
        width: 70px;
        height: 70px;
        padding: 15px;
        flex: 1;
        .svg-icon {
          width: 70px;
          height: 70px;
          color: #fff;
        }
      }
      .close {
        width: 50px;
        height: 50px;
        margin-top: 30px;
        margin-left: 100px;
        flex: 1;
        .close-icon {
          width: 50px;
          height: 50px;
          fill: #fff;
          stroke: #fff;
        }
      }
      .dialog-title {
        color: #fff;
        margin-left: 90px;
        text-align: left;
        flex: 10;
        .row1 {
          margin-top: 5px;
        }
        .row2 {
          margin-top: 14px;
          color: $fontColor1;
        }
      }
    }
  }

  .router-slid-enter-active,
  .router-slid-leave-active {
    transition: all 0.5s;
  }
  .router-slid-enter,
  .router-slid-leave-to {
    transform: scaleY(0);
    transform-origin: 0 1%;
    opacity: 0;
  }

  .shipperCompanyName {
    width: 260px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
  }
  .shipperCity {
    width: 260px;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .shipperCity {
    color: $fontColor1;
  }
  .cargoInfoWeightVolume {
    color: $background2;
    font-size: 12px;
    font-family: "montserratLight";
  }
}
</style>
